@font-face {
  font-family: 'Syntax LT Std Roman';
  src: local('Syntax LT Std'), url(./assets/fonts/SyntaxLTStd-Roman.otf) format('opentype');
}

@font-face {
  font-family: 'Syntax LT Std Black';
  src: local('Syntax LT Std'), url(./assets/fonts/SyntaxLTStd-Black.otf) format('opentype');
} 

 @font-face {
  font-family: 'Syntax LT Std Bold';
  src: local('Syntax LT Std'), url(./assets/fonts/SyntaxLTStd-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Syntax LT Std Italic';
  src: local('Syntax LT Std'), url(./assets/fonts/SyntaxLTStd-Italic.otf) format('opentype');
}

@font-face {
  font-family: 'Syntax LT Std UltraBlack';
  src: local('Syntax LT Std'), url(./assets/fonts/SyntaxLTStd-UltraBlack.otf) format('opentype');
}

#localVideo {
  height: 112px;
  border-radius: 4px;
  z-index: 1;
  margin: 1%;
  position: absolute;
  z-index: 1000;
}
.video-wrapper {
  height: 100%;
  background: #252839;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  position: relative;
}
#remoteVideo {
  width: 100%;
  height: 100%;
  z-index: -1;
}
#remoteVideo.hide {
  display: none;
}

.controls{
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  z-index: 1000;
  width: 100%;
  
}
.contact-name {
  position: absolute;
  bottom: 40px;
  margin-left: 3%;
  font-size: large;
  font-weight: bold;
  color: rgb(196, 196, 196);
  text-shadow: 0 1px rgba(0,0,0,.7);
}

.prof-name {
  position: absolute;
  top: 0px;
  margin-left: 60%;
  font-size: large;
  font-weight: bold;
  color: rgb(196, 196, 196);
  text-shadow: 0 1px rgba(0,0,0,.7);
}